
















import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
@Component
export default class paySuccess extends Vue {
  returnHomePage () {
    this.$router.push('/');
  }

  viewOrderDetails () {
    let rnpId = this.id;
    this.$router.push({ name: 'regNPayResult', params: { rnpId } });
  }

  get id () {
    return this.$route.params.id;
  }

  mounted () {}
}
